import React, { useState, useEffect, useContext } from "react"
import { graphql, navigate } from "gatsby"
import { CarPrice, RecommendVideo } from "../components/model"
import Select from "@material-ui/core/Select"
import {
  Spinner,
  DCXButton,
  Select as CustomSelect,
  Header,
  Footer,
  SEO,
  Image,
  OptionEquipment,
} from "../components"
import { KeyboardArrowRightIcon, getIcon } from "../assets"
import { CarContext, UIContext } from "../context"
import {
  urlWithSession,
  getColorCircleStyle,
  getModelShortNameOrDefault,
  getMinimunPrices,
} from "../utils"
import { useSpecialDevice } from "../hooks"

export default function Comparador({ data }) {
  const { openFinancingDrawer } = useContext(UIContext)

  /* Fetch all data */
  let {
    allModels: { nodes: allModels },
  } = data

  // Ignore models partially uploaded
  allModels = allModels.filter(model => !model.partialUpload)

  /* Fetch context */
  const appContext = useContext(CarContext)

  const [forStore] = useSpecialDevice() || true // temporally

  //console.log("CAR CONTEXT:", appContext)
  //console.log("ALL MODELS:", allModels)

  const calculateMaxModels = deviceWidth => {
    if (deviceWidth && deviceWidth < 810) return 2
    return 3
  }

  const [availableEquipments, setAvailableEquipments] = useState([])

  /* Save on compoennt state the state of modal and dropdowns */
  const [cmpState, setCmpState] = useState({
    nRows: calculateMaxModels(appContext.windowWidth),
    openDropdowns: {
      0: false,
      1: false,
      2: false,
    },
    allEquipmentsShown: false,
  })

  useEffect(() => {
    /* Evaluate if needed and perform any update to selections on context */
    handleSelections(appContext, allModels)
  }, [appContext, allModels])

  /* Keep tracking of window size to decide if render 2 or 3 columns */
  useEffect(() => {
    function handleResize() {
      setCmpState(prevState => ({
        ...prevState,
        nRows: calculateMaxModels(appContext.windowWidth),
      }))
    }

    function handleScroll() {
      // Get width when fixed
      const overviewEl = document.getElementsByClassName("overview") || []
      const width = overviewEl[0]?.getBoundingClientRect().width

      // Get vertical offset when becomes fixed
      const titleEl = document.getElementsByClassName("main-title") || []
      const titleHeight = titleEl[0]?.getBoundingClientRect().height

      // Get Selector elements
      const elements = document.getElementsByClassName("model-select")
      const isMobile = appContext.windowWidth < 760
      if (window.pageYOffset > titleHeight + (isMobile ? 54 : 0) - 5) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add("sticky-active")
          elements[i].style.width = `${width}px`
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove("sticky-active")
          elements[i].style.width = "100%"
        }
      }
    }

    if (typeof window !== `undefined`) {
      window.addEventListener("resize", handleResize)
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [appContext.windowWidth])

  const readyForCompare =
    Boolean(appContext.comparatorSelections) &&
    appContext.comparatorSelections.filter(Boolean).length === 3

  return (
    <>
      <SEO
        url="https://dercocenterx.cl/comparador"
        title="Compara tus autos preferidos | DercoCenter X"
        description={`Compara los modelos en las Marcas: Suzuki, Mazda, Renault, con los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.`}
      ></SEO>
      <Header page="comparator" />
      <div className="comparator-page">
        <div className="main-title">
          <h1>Compara tus autos preferidos</h1>
          <DCXButton
            className="button-edit"
            type="tertiary"
            text="EDITAR PAGO"
            arrow="right"
            onClick={openFinancingDrawer}
          />
        </div>

        {getOverviewFeats({
          allModels,
          cmpState,
          setCmpState,
          appContext,
        })}

        {getSectionTitle("DATOS", "PRÁCTICOS", cmpState, appContext)}

        {readyForCompare &&
          appContext.comparatorSelections[0].practicalData.sections.map(
            (sec, idx) => (
              <React.Fragment key={idx}>
                {getSubsectionTitle(sec.name)}
                {getPractDataCategoryFeats(cmpState, sec.name, appContext)}
              </React.Fragment>
            )
          )}

        {getSubsectionTitle(
          "Conoce más de tus modelos",
          forStore ? "hide" : ""
        )}

        {getRecommendVideo(cmpState, appContext)}

        {getSectionTitle("FICHA", "TÉCNICA", cmpState, appContext)}

        {getSubsectionTitle("Dimensiones")}

        {getTechnicalDataDimFeats(cmpState, appContext)}

        {readyForCompare &&
          appContext.comparatorSelections[0].technicalData.sections.map(
            (sec, index) =>
              index !== 1 ? (
                <React.Fragment key={index}>
                  {getSubsectionTitle(sec.name)}
                  {getCategoryDataTextList(cmpState, sec.name, appContext)}
                </React.Fragment>
              ) : (
                <React.Fragment key={index}>
                  <div className="section-container">
                    <div className="subsection-title">
                      <span>{sec.name}</span>
                    </div>
                    <div className="subsection-line"></div>
                  </div>
                  {getEquipmentOptions(cmpState, appContext)}
                  <DCXButton
                    className="view-all-button"
                    type="secondary"
                    text={
                      cmpState.allEquipmentsShown ? "VER MENOS" : "VER TODOS"
                    }
                    onClick={() =>
                      setCmpState(prev => ({
                        ...prev,
                        allEquipmentsShown: !prev.allEquipmentsShown,
                      }))
                    }
                  />
                </React.Fragment>
              )
          )}

        {getFooterButtons(cmpState, appContext)}
      </div>
      <Footer />
    </>
  )
}

const getEquipmentOptions = (cmpState, appContext) => {
  return (
    <div className="option-list equipments">
      {appContext.comparatorSelections
        .slice(0, cmpState.nRows)
        .map((model, colIndex) =>
          model.configOptions.packs
            .filter(
              pack => cmpState.allEquipmentsShown || pack.name === "BÁSICO"
            )
            .map((pack, idx) => (
              <div
                key={idx + pack.name}
                className="list-item equipment"
                style={{
                  backgroundColor: "transparent",
                  gridColumn: colIndex + 1,
                  gridRow: 1 + idx,
                }}
              >
                <OptionEquipment
                  option={{
                    info: pack,
                    isFirstPack: !pack.previousEquip,
                  }}
                  alwaysExpanded={!pack.previousEquip}
                  withoutPriceSection
                />
              </div>
            ))
        )}
    </div>
  )
}

const fillMissingOptions = (opts1, opts2, opts3 = null) => {
  const maxOptions = Math.max(opts1.length, opts2.length, opts3?.length || 0)

  const emptyEntry = {
    empty: true,
    name: "",
    features: Array(opts1[0].features?.length || 0).fill({
      empty: true,
      name: "",
    }),
  }

  const newOpts1 = opts1.concat(
    Array(maxOptions - opts1.length).fill(emptyEntry)
  )
  const newOpts2 = opts2.concat(
    Array(maxOptions - opts2.length).fill(emptyEntry)
  )
  const newOpts3 = opts3
    ? opts3.concat(Array(maxOptions - opts3.length).fill(emptyEntry))
    : null

  return [newOpts1, newOpts2, ...(newOpts3 ? [newOpts3] : [])]
}

const handleSelections = (appContext, allModels) => {
  /* Save for the first time the default selected models on context */
  const { comparatorSelections, setComparatorSelections } = appContext
  let similarModels
  if (!comparatorSelections.length) {
    //Coming to comparator for the first time
    //Coming from home. Select default threesome
    similarModels = [1].concat(allModels.find(m => m.ID === 1).similarModels)
  } else if (comparatorSelections.length === 1) {
    //Coming from /car-model buttons on practical and technical sections
    similarModels = [comparatorSelections[0].ID].concat(
      comparatorSelections[0].similarModels
    )
  } else if (comparatorSelections.length === 3) {
    //Updating inside comparator. No changes required here.
  }
  if (similarModels)
    setComparatorSelections(
      similarModels.map(id => allModels.find(model => model.ID === id))
    )
}

const getSectionTitle = (first, second, cmpState, appContext) => (
  <div className="section-container">
    {appContext.comparatorSelections
      .slice(0, cmpState.nRows)
      .map((_, index) => (
        <div key={index} className="section-title">
          {index === 0 && (
            <h2>
              <div className="gray">{first}</div>
              <div className="dark-gray">{second}</div>
            </h2>
          )}
        </div>
      ))}
  </div>
)

const getSubsectionTitle = (text, className) => (
  <div className={`section-container ${className || ""}`}>
    <div className="subsection-title">{text}</div>
    <div className="subsection-line"></div>
  </div>
)

const getOverviewFeats = ({ allModels, cmpState, setCmpState, appContext }) => {
  return (
    <div className="comparator-container">
      {appContext.comparatorSelections
        .slice(0, cmpState.nRows)
        .map((model, index) => (
          <div key={index} className="option overview">
            <CustomSelect
              group="brand"
              className="model-select"
              id={`comparator-select-model-${index}`}
              props={{
                values: allModels,
                propertyValue: "name",
                propertyShow: "name",
                actual: model.name,
              }}
              onChange={response => {
                const car = allModels.filter(item => item.name === response)[0]
                // toggleModelsDropdown(index, setCmpState)
                selectCar(car, index, setCmpState, appContext)
              }}
              placeholder="Escoge"
            ></CustomSelect>

            <Image
              className="car-image"
              filename={model.gallery
                .find(g => g.category === "model")
                .images[0].url.split("/")
                .pop()}
              alt=""
            ></Image>

            <div className="feature price">
              {appContext.loading ? (
                <Spinner
                  className="loader"
                  size={60}
                  c={12} //Depend indirectly of size
                  color="#b20d27"
                  strokes={{ line: 3, circle: 6 }}
                />
              ) : (
                <>
                  <CarPrice
                    prices={getMinimunPrices(
                      appContext.modelsPricesStocks,
                      model?.versions.map(v => v.id),
                      { allowOutOfStock: true }
                    )}
                    appContext={appContext}
                    setMoreInfo={() =>
                      appContext.setSelectedVersion(
                        getMinimunPrices(
                          appContext.modelsPricesStocks,
                          model.versions.map(v => v.id),
                          { allowOutOfStock: true }
                        )
                      )
                    }
                    center
                    chips={false}
                  />
                </>
              )}
            </div>
            <div className="feature">
              <DCXButton
                className="button-buy"
                id={`comparator-i-want-it-button-top-${index}`}
                type="primary"
                text={`ARMA TU ${getModelShortNameOrDefault(model)}`}
                onClick={() =>
                  navigate(urlWithSession(`/config/${model.slug}`))
                }
              />
            </div>

            <div className="feature">
              <div className="feat-title">Colores disponibles</div>
              <div className="color-list">
                {model.configOptions.colors.map((item, idx) => (
                  <div key={idx} className="circle-container">
                    <div
                      className="color"
                      style={getColorCircleStyle(item.hexa)}
                    ></div>
                  </div>
                ))}
              </div>
            </div>

            {model.highlights.map((item, idx) => (
              <div key={idx} className="feature">
                <img
                  className="icon-svg"
                  src={getIcon(item.icon.name, "black")}
                  alt=""
                />
                <div className="feat-title">{item.data || item.value}</div>
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}

const selectCar = (car, index, setCmpState, appContext) => {
  /* Update selections on Context */
  const { setComparatorSelections } = appContext
  setComparatorSelections(prev =>
    prev.map((model, idx) => (idx === index ? car : model))
  )
}

const getPractDataCategoryFeats = (cmpState, sectionName, appContext) => (
  <div className="comparator-container">
    {appContext.comparatorSelections
      .slice(0, cmpState.nRows)
      .map((model, index) => (
        <div key={index} className="option">
          {model.practicalData.sections
            .find(s => s.name === sectionName)
            ?.specs.map((feat, idx) => (
              <div key={idx} className="feature">
                <img
                  className="icon-svg"
                  src={getIcon(feat.icon.name, "black")}
                  alt=""
                />
                <div className="feat-title">{feat.name}</div>
                <div className="feat-description">
                  {feat.value}{" "}
                  {feat.optional && (
                    <span className="optional">(opcional)</span>
                  )}
                </div>
              </div>
            ))}
          {sectionName === "Dimensiones" && (
            <div className="feature">
              <div className="parking">
                <img
                  src={
                    model.gallery.find(g => g.category === "parking").images[1]
                      .url
                  }
                  alt="parking"
                ></img>
                <div className="title-parking">
                  Estacionamiento pequeño (medidas mínimas permitidas)
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
  </div>
)

const getRecommendVideo = (cmpState, appContext) => (
  <div className={`comparator-container`}>
    {appContext.comparatorSelections
      .slice(0, cmpState.nRows)
      .map((model, index) => (
        <div key={index} className="option">
          {model.recommendVideo ? (
            <RecommendVideo
              className="video"
              videoLink={model.recommendVideo.url}
            ></RecommendVideo>
          ) : null}
        </div>
      ))}
  </div>
)

const getTechnicalDataDimFeats = (cmpState, appContext) => (
  <>
    <div className="comparator-container">
      {appContext.comparatorSelections
        .slice(0, cmpState.nRows)
        .map((model, index) => (
          <div key={index} className="option">
            {model.dimensionSpecs.overall.map((feat, idx) => (
              <div key={idx} className="feature">
                <img
                  className="icon-svg"
                  src={getIcon(feat.icon.name, "black")}
                  alt=""
                />
                <div className="feat-title">{feat.name}</div>
                <div className="feat-description">{feat.value}</div>
              </div>
            ))}
          </div>
        ))}
    </div>
    {getSubsectionTitle("")}
    <div className="comparator-container">
      {appContext.comparatorSelections
        .slice(0, cmpState.nRows)
        .map((model, index) => (
          <div key={index} className="option">
            {model.dimensionSpecs.lengths.map((feat, idx) => (
              <div className="feature" key={idx}>
                <img
                  className="icon-svg"
                  src={getIcon(feat.icon.name, "black")}
                  alt=""
                />
                <div className="feat-title">{feat.name}</div>
                <div className="feat-description">{feat.value}</div>
              </div>
            ))}
          </div>
        ))}
    </div>
  </>
)

const getOptionsLines = (options, startValue) =>
  options.reduce(
    (acum, option) =>
      acum + (option.name ? 1 : 0) + (option.features?.length || 0),
    startValue
  )

const getOptionRow = (options, currOptIdx) => {
  if (currOptIdx === 0) return 2
  return getOptionsLines(options.slice(0, currOptIdx), 2)
}

const getCategoryDataTextList = (cmpState, sectionName, appContext) => {
  const sectionProcessedOptions = fillMissingOptions(
    ...appContext.comparatorSelections
      .slice(0, cmpState.nRows)
      .map(
        model =>
          model.technicalData.sections.find(sec => sec.name === sectionName)
            .options
      )
  )

  return (
    <div className="option-list">
      {sectionProcessedOptions?.map((options, colIndex) => {
        return (
          <React.Fragment key={colIndex}>
            <div
              className="list-item first-row"
              style={{ gridColumn: colIndex + 1, gridRow: 1 }}
            ></div>
            {options.map((option, optIdx) => {
              const optBaseRowIndex = getOptionRow(options, optIdx)
              return (
                <React.Fragment key={optIdx}>
                  {(option.empty || option.name) && (
                    <div
                      className={`list-item isTitle`}
                      style={{
                        gridColumn: colIndex + 1,
                        gridRow: optBaseRowIndex,
                      }}
                    >
                      {option.name}
                    </div>
                  )}
                  {option.features?.map((feat, rowIndex) => (
                    <div
                      key={rowIndex}
                      className={`list-item`}
                      style={{
                        gridColumn: colIndex + 1,
                        gridRow: optBaseRowIndex + rowIndex + 1,
                      }}
                    >
                      {!feat.empty ? (
                        <div className="text">
                          <KeyboardArrowRightIcon
                            className="list-pointer"
                            color="primary"
                          />
                          <div>
                            {feat.name + (feat.value ? ": " + feat.value : "")}
                          </div>
                        </div>
                      ) : (
                        <div className="text empty"></div>
                      )}
                    </div>
                  ))}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const getFooterButtons = (cmpState, appContext) => (
  <div className="comparator-container">
    {appContext.comparatorSelections
      .slice(0, cmpState.nRows)
      .map((model, index) => (
        <div key={index} className="option">
          <div className="feature">
            <DCXButton
              className="button-buy"
              id={`comparator-i-want-it-button-bottom-${index}`}
              type="primary"
              text="LO QUIERO"
              onClick={() => navigate(urlWithSession(`/config/${model.slug}`))}
            />
          </div>
        </div>
      ))}
  </div>
)

export const query = graphql`
  query FULL_QUERY_2 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`
